var __jsx = React.createElement;
import React from 'react';
import Anchor from '../../atoms/Anchor';
import analyticsConstants from '../../../constants/analyticsConstants';
import { HEADER_PARENTS } from '../../../constants/globalConstants';
var globalHeader = analyticsConstants.globalHeader,
  globalFooter = analyticsConstants.globalFooter;
var analyticsMap = {
  header: globalHeader,
  footer: globalFooter
};
var NavItem = function NavItem(_ref) {
  var _item$sys;
  var item = _ref.item,
    menuType = _ref.menuType,
    parent = _ref.parent,
    parentType = _ref.parentType,
    index = _ref.index,
    totalItems = _ref.totalItems;
  var analytics = analyticsMap[parentType];
  var styleType;
  var analyticsType = analytics.analyticsType.GLOBAL_NAV;
  if (menuType === 'cta') {
    var isMobileMenuGrid = parent == HEADER_PARENTS.MOBILE_MENU_GRID;
    var isPrimaryCta = isMobileMenuGrid ? index === 0 : index + 1 === totalItems;
    styleType = isPrimaryCta ? 'primary-anchor' : 'secondary-anchor';
    analyticsType = index + 1 === totalItems && parentType === 'footer' ? analytics.analyticsType.GLOBAL_NAV_FOOTER_PRIMARY : analytics.analyticsType.GLOBAL_NAV;
  }
  return __jsx(Anchor, {
    "data-entry-id": (_item$sys = item.sys) === null || _item$sys === void 0 ? void 0 : _item$sys.id,
    className: "nva-nav-list__item u-button",
    styleType: styleType,
    title: item.label,
    "aria-label": item.ariaLabel,
    to: item.url,
    hyperlinkType: item.hyperlinkType,
    ctaBehavior: item.behavior,
    "data-analytics-type": analyticsType,
    "data-analytics-value": (item === null || item === void 0 ? void 0 : item.dataAnalyticsValue) || analytics.analyticsValue.GLOBAL_NAV_CATEGORY_LINK,
    icon: item.icon,
    iconPosition: item.iconPosition
  }, item.label);
};
NavItem.defaultProps = {
  menuType: 'link',
  parentType: 'header'
};
export default NavItem;