var SEARCH_VAR = {
  width: {
    "private": '--_nva-search--width',
    "public": '--nva-search--width',
    value: '220px'
  },
  padding: {
    "private": '--_nva-search--padding',
    "public": '--nva-search--padding',
    value: 'var(--g-spacing-3) var(--g-spacing-5) var(--g-spacing-3) var(--_nva-search--submit-width)',
    inherit: 'inherit'
  },
  borderRadius: {
    "private": '--_nva-search--border-radius',
    "public": '--nva-search--border-radius',
    value: 'var(--g-border-radius)',
    inherit: 'inherit'
  },
  borderWidth: {
    "private": '--_nva-search--border-width',
    "public": '--nva-search--border-width',
    value: '1px'
  },
  borderColor: {
    "private": '--_nva-search--border-color',
    "public": '--nva-search--border-color',
    value: 'hsl(var(--g-color-neutral-800))'
  },
  textColor: {
    "private": '--_nva-search--text-color',
    "public": '--nva-search--text-color',
    value: 'hsl(var(--g-color-neutral-800))'
  },
  backgroundColor: {
    "private": '--_nva-search--background-color',
    "public": '--nva-search--background-color',
    value: 'hsl(var(--g-color-white))'
  },
  submitWidth: {
    "private": '--_nva-search--submit-width',
    "public": '--nva-search--submit-width',
    value: 'calc(24px + var(--g-spacing-3))',
    inherit: 'inherit'
  },
  submitIconColor: {
    "private": '--_nva-search--submit-icon-color',
    "public": '--nva-search--submit-icon-color',
    value: 'hsl(var(--g-color-neutral-800))'
  }
};
export default SEARCH_VAR;